import gql from 'graphql-tag';

export const GET_PROJECTS_QUERY = gql`
    query Projects($limit: Int, $offset: Int) {
        entries: projectsEntries(limit: $limit, offset: $offset, orderBy: "postDate desc") {
            ... on projects_default_Entry {
                id
                url
                title
                location
                imagePresentation
                projectFeaturedImage(limit: 1) @transform(width: 470, format: "webp") {
                    id
                    url
                    srcset(sizes: ["640", "768", "1024", "1366", "1600"])
                }
            }
        }
    }
`;
