<template>
    <a
        :href="project.url"
        class="project-item block p-3 xl:p-4 w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 group"
    >
        <figure
            :class="{
                'animate-item': animate,
                'animate-item--mounted': mounted,
            }"
            :style="{ 'transition-delay': `${index * 50}ms` }"
        >
            <div
                class="bg-black"
                :class="{
                    'aspect-w-4 aspect-h-3': project.imagePresentation === 'landscape',
                    'aspect-w-3 aspect-h-4': project.imagePresentation === 'portrait',
                    'aspect-w-1 aspect-h-1': project.imagePresentation === 'square',
                }"
            >
                <img
                    class="block object-cover object-center group-hover:opacity-80 transition-opacity ease-in-out duration-300 lazyload"
                    :data-srcset="featuredImage.srcset"
                    :data-src="featuredImage.url"
                    data-sizes="auto"
                    :alt="project.title"
                    sizes="auto"
                />
            </div>

            <figcaption class="mt-2 text-xs sm:text-sm">
                <h2 class="font-bold inline">{{ project.title }}</h2>
                <span class="text-primary-500 font-medium"> / </span>
                <p class="inline font-light">{{ project.location }}</p>
            </figcaption>
        </figure>
    </a>
</template>

<script>
    export default {
        props: {
            project: {
                type: Object,
                required: true,
            },
            index: {
                type: Number,
                required: true,
            },
            animate: {
                type: Boolean,
                required: true,
            },
            mounted: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                inViewportOnMount: true,
            };
        },

        computed: {
            featuredImage() {
                const [ featuredImage ] = this.project.projectFeaturedImage;
                return featuredImage || null;
            },
        },
    };
</script>

<style lang="postcss">
    .animate-item {
        @apply opacity-0;

        transform: translateY(-50%);
        transition: transform 0.6s, opacity 0.6s;
        transition-timing-function: cubic-bezier(.250, .100, .250, 1);

        &--mounted {
            @apply opacity-100;

            transform: translateY(0);
        }
    }
</style>
