<template>
    <div class="splash flex items-center fixed inset-0 bg-white z-50">
        <div class="container w-full">
            <logo class="splash-logo block w-full max-w-lg h-auto mx-auto" />
        </div>
    </div>
</template>

<script>
    import Logo from '@/img/logo.svg';
    import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

    export default {
        components: {
            Logo,
        },

        emits: [ 'complete' ],

        mounted() {
            this.emitter.on('projects.layout', this.onLayout);

            disableBodyScroll(this.$el, {
                reserveScrollBarGap: true,
            });

            setTimeout(() => {
                this.$emit('complete');
            }, 2500);
        },

        beforeUnmount() {
            this.emitter.off('projects.layout', this.onLayout);
            this.emitter.emit('splash.close');
        },

        methods: {
            onLayout() {
                clearAllBodyScrollLocks();

                disableBodyScroll(this.$el, {
                    reserveScrollBarGap: true,
                });
            },
        },
    };
</script>

<style lang="postcss">
    .splash-logo {
        .logo-letters {
            @apply opacity-0 transition-opacity duration-1000 ease-in-out;

            animation: logo 1.5s ease-in-out forwards;
            animation-delay: 0.5s;
        }
    }

    @keyframes logo {
        0% {
            @apply opacity-0;
        }

        100% {
            @apply opacity-100;
        }
    }
</style>
