// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/css/style.pcss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';


// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp } from 'vue';
import apolloProvider from './apollo';

// Animate on scroll
import AOS from 'aos';

// Components
import Splash from './components/Splash.vue';
import Projects from './components/Projects.vue';
import MainMenu from './components/MainMenu.vue';
import Rot13Email from './components/Rot13Email.vue';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Pagination } from 'swiper';

// Vue masonry
import { VueMasonryPlugin } from 'vue-masonry';

import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import mitt from 'mitt';

const emitter = mitt();

const app = createApp({
    delimiters: [ '${', '}' ],

    components: {
        Swiper,
        SwiperSlide,
    },

    data() {
        let isSplashShown;

        if (location.pathname === '/') {
            isSplashShown = !(sessionStorage.getItem('isSplashShown') === 'true');
            sessionStorage.setItem('isSplashShown', 'true');
        }

        return {
            swiperModules: [ Autoplay, Pagination ],
            isMenuOpen: false,
            isSplashShown,
        };
    },

    mounted() {
        setTimeout(() => {
            AOS.init({
                disable: window.outerWidth < 768,
                duration: 600,
            });
        }, 300);
    },

    methods: {
        onToggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        onFinishSplash() {
            clearAllBodyScrollLocks();
        },
    },
});

app.config.globalProperties.emitter = emitter;

app.component('Splash', Splash);
app.component('Projects', Projects);
app.component('MainMenu', MainMenu);
app.component('Rot13Email', Rot13Email);

app.use(apolloProvider);
app.use(VueMasonryPlugin);

app.mount('#app');

document.body.classList.remove('preload');

window.AOS = AOS;
