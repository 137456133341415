<template>
    <div class="main-menu" :class="{ 'main-menu--mounted': isMounted }">
        <button type="button" class="menu !absolute top-9 right-4 md:right-8" :class="{ 'menu--open': isMenuOpen }" @click.prevent="onToggleMenu">
            <span class="sr-only">Toggle menu</span>

            <span class="menu__wrapper">
                <span class="menu__line" />
                <span class="menu__line" />
                <span class="menu__line" />
            </span>
        </button>

        <div class="container h-full py-32">
            <nav class="flex justify-center h-full">
                <ul class="my-auto">
                    <li
                        v-for="(node, index) in nodes"
                        :key="node.id"
                        class="menu__node"
                        :style="{
                            transitionDelay: `${index * 100}ms`,
                        }"
                    >
                        <a v-bind="node.attributes" v-text="node.text" />
                    </li>

                    <li
                        class="menu__node"
                        :style="{
                            transitionDelay: `${nodes.length * 100}ms`,
                        }"
                    >
                        <slot />
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
    import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

    export default {
        props: {
            nodes: {
                type: Array,
                required: true,
            },
            isMenuOpen: {
                type: Boolean,
                required: true,
            },
        },

        emits: [ 'close' ],

        data() {
            return {
                isMounted: false,
            };
        },

        mounted() {
            disableBodyScroll(this.$el, {
                reserveScrollBarGap: true,
            });

            setTimeout(() => {
                this.isMounted = true;
            }, 100);
        },

        beforeUnmount() {
            enableBodyScroll(this.$el);
        },

        methods: {
            onToggleMenu() {
                this.$emit('close');
            },
        },
    };
</script>

<style>
    .main-menu {
        @apply block fixed inset-0 bg-black/90 text-white z-50;
    }

    .menu__node {
        @apply block opacity-0 mb-10 last:mb-0;
        @apply transition-all duration-500 ease-in-out;

        transform: translateY(-100%);
    }

    .menu__link {
        @apply text-4xl text-[#666];
        @apply transition-colors duration-300 ease-in-out;

        &--active,
        &:hover {
            @apply text-white;
        }
    }

    .main-menu--mounted .menu__node {
        @apply opacity-100;

        transform: translateY(0);
    }
</style>
