<template>
    <section
        v-infinite-scroll="onLoadMore"
        infinite-scroll-disabled="disableLazyLoad"
        infinite-scroll-distance="400"
        infinite-scroll-immediate-check="false"
    >
        <div class="container max-w-none">
            <div v-masonry class="-m-4 xl:-m-4" transition-duration="0" item-selector=".project-item">
                <project-item
                    v-for="(project, index) in entries"
                    :key="project.id"
                    v-masonry-tile
                    :project="project"
                    :index="index"
                    :animate="animate"
                    :mounted="mounted"
                />
            </div>
        </div>
    </section>
</template>

<script>
    import infiniteScroll from 'vue3-infinite-scroll-good';
    import vClickOutside from 'click-outside-vue3';
    import { GET_PROJECTS_QUERY } from '../graphql/projects';
    import ProjectItem from '@/js/components/ProjectItem.vue';

    export default {
        components: {
            ProjectItem,
        },

        directives: {
            infiniteScroll,
            clickOutside: vClickOutside.directive,
        },

        props: {
            splashClosed: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                entries: [],
                page: 0,
                entriesPerPage: 24,
                disableLazyLoad: true,
                mounted: false,
                animate: true,
            };
        },

        apollo: {
            entries: {
                query: GET_PROJECTS_QUERY,
                variables() {
                    return {
                        limit: this.entriesPerPage,
                        offset: 0,
                    };
                },
                update(data) {
                    this.disableLazyLoad = data.entries.length < this.entriesPerPage;
                    return data.entries;
                },
            },
        },

        watch: {
            entries() {
                this.emitter.emit('projects.layout');
            },
        },

        mounted() {
            this.emitter.on('splash.close', this.onSplashClose);

            if (this.splashClosed) {
                console.log('splash closed');

                setTimeout(() => {
                    this.mounted = true;
                }, 300);
            }
        },

        beforeUnmount() {
            this.emitter.off('splash.close', this.onSplashClose);
        },

        methods: {
            onSplashClose() {
                setTimeout(() => {
                    this.mounted = true;
                }, 300);
            },
            onLoadMore() {
                this.page++;
                const offset = this.page * this.entriesPerPage;

                console.log(this.$apollo);

                this.$apollo.queries.entries.fetchMore({
                    variables: {
                        limit: this.entriesPerPage,
                        offset,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        const entries = [
                            ...previousResult.entries,
                            ...fetchMoreResult.entries,
                        ];

                        this.animate = false;
                        this.disableLazyLoad = fetchMoreResult.entries.length < this.entriesPerPage;

                        return {
                            entries,
                        };
                    },
                });
            },
        },
    };
</script>
